export default defineNuxtRouteMiddleware((to) => {
	/** Redirect old product base url to '/products/' */
	const oldProductsPathBase = /\/monroe-mi-dispensary\/(.+)/;
	const match = to.path.match(oldProductsPathBase);

	if (match) {
		return navigateTo(`/products/${match[1]}`);
	}

	/** Redirects for all pages under '/learn/' */
	const path = to.path.endsWith('/') ? to.path.slice(0, -1) : to.path;

	const redirectionMap = {
		'/michigan-dispensary-purchase-limits': '/learn/michigan-dispensary-purchase-limits',
		'/terpenes-over-potency': '/learn/terpenes-over-potency',
		'/cannabinoids': '/learn/cannabinoids',
		'/what-is-cannabis': '/learn/what-is-cannabis',
		'/cannabis-anatomy': '/learn/cannabis-anatomy',
		'/cannabis-history': '/learn/cannabis-history',
		'/dispensaries/michigan-dispensary-near-ohio': '/michigan-dispensary-near-ohio',
		'/monroe-dispensary-michigan-recreational': '/',
		'/refund-policy': '/return-policy',
	};

	const redirectPath = redirectionMap[path];

	if (redirectPath) {
		return navigateTo(redirectPath);
	}

	return;
});
