import { default as _36100_45ouncesGCh5fSOlJNMeta } from "/tmp/build_f759bf60/pages/$100-ounces.vue?macro=true";
import { default as accessibilityNEk2OviTNjMeta } from "/tmp/build_f759bf60/pages/accessibility.vue?macro=true";
import { default as _91slug_93WB2DhL28GNMeta } from "/tmp/build_f759bf60/pages/brands/[slug].vue?macro=true";
import { default as build_45a_45packdKN3HkBjf3Meta } from "/tmp/build_f759bf60/pages/build-a-pack.vue?macro=true";
import { default as cannabis_45pricingV4jhXnKq4UMeta } from "/tmp/build_f759bf60/pages/cannabis-pricing.vue?macro=true";
import { default as checkoutsITevn648gMeta } from "/tmp/build_f759bf60/pages/checkout.vue?macro=true";
import { default as contactbxKKR1LzSPMeta } from "/tmp/build_f759bf60/pages/contact.vue?macro=true";
import { default as confirmSjWPzuExAdMeta } from "/tmp/build_f759bf60/pages/dashboard/confirm.vue?macro=true";
import { default as indexDPC2fYfGF6Meta } from "/tmp/build_f759bf60/pages/dashboard/index.vue?macro=true";
import { default as _91id_93XeOKLvTBMhMeta } from "/tmp/build_f759bf60/pages/dashboard/jobs/[id].vue?macro=true";
import { default as createoG7hiopMukMeta } from "/tmp/build_f759bf60/pages/dashboard/jobs/create.vue?macro=true";
import { default as indexoODxw4r1UdMeta } from "/tmp/build_f759bf60/pages/dashboard/jobs/index.vue?macro=true";
import { default as loginCJ7treLhRZMeta } from "/tmp/build_f759bf60/pages/dashboard/login.vue?macro=true";
import { default as bundle_45image_45buildernpD5zGOf5yMeta } from "/tmp/build_f759bf60/pages/dashboard/media/bundle-image-builder.vue?macro=true";
import { default as indexMdv259CX6QMeta } from "/tmp/build_f759bf60/pages/dashboard/media/index.vue?macro=true";
import { default as indexoID3PJyoQMMeta } from "/tmp/build_f759bf60/pages/dashboard/menu/index.vue?macro=true";
import { default as _91_46_46_46slug_933IwQLvziQ1Meta } from "/tmp/build_f759bf60/pages/dashboard/pages/[...slug].vue?macro=true";
import { default as homec3E8f2SsZZMeta } from "/tmp/build_f759bf60/pages/dashboard/pages/home.vue?macro=true";
import { default as indexR2xwN2wK4CMeta } from "/tmp/build_f759bf60/pages/dashboard/pages/index.vue?macro=true";
import { default as _91id_93gARRZfiZM9Meta } from "/tmp/build_f759bf60/pages/dashboard/products/[id].vue?macro=true";
import { default as indexz0fcocuKNNMeta } from "/tmp/build_f759bf60/pages/dashboard/products/index.vue?macro=true";
import { default as forecasts6Chl57pYqHMeta } from "/tmp/build_f759bf60/pages/dashboard/reporting/forecasts.vue?macro=true";
import { default as _91id_93yeRpbk80khMeta } from "/tmp/build_f759bf60/pages/dashboard/reviews/[id].vue?macro=true";
import { default as index0LYRzKI03PMeta } from "/tmp/build_f759bf60/pages/dashboard/reviews/index.vue?macro=true";
import { default as settings4RCd3ucMSgMeta } from "/tmp/build_f759bf60/pages/dashboard/settings.vue?macro=true";
import { default as themedBTwIWZwJLMeta } from "/tmp/build_f759bf60/pages/dashboard/theme.vue?macro=true";
import { default as _91id_93l0qccFZyhvMeta } from "/tmp/build_f759bf60/pages/dashboard/users/[id].vue?macro=true";
import { default as index3MiApy7Hg3Meta } from "/tmp/build_f759bf60/pages/dashboard/users/index.vue?macro=true";
import { default as indexiGgbeGw12tMeta } from "/tmp/build_f759bf60/pages/dashboard/web-vitals/index.vue?macro=true";
import { default as dashboardHFRVqcQi8lMeta } from "/tmp/build_f759bf60/pages/dashboard.vue?macro=true";
import { default as dispensary_45birthday_45deals_45michigannfpADOatU8Meta } from "/tmp/build_f759bf60/pages/dispensary-birthday-deals-michigan.vue?macro=true";
import { default as dispensary_45dealsZR7iJlcHoHMeta } from "/tmp/build_f759bf60/pages/dispensary-deals.vue?macro=true";
import { default as first_45time_45dispensary_45dealsNbznXAmBmPMeta } from "/tmp/build_f759bf60/pages/first-time-dispensary-deals.vue?macro=true";
import { default as glass_45jars73T7EwJraQMeta } from "/tmp/build_f759bf60/pages/glass-jars.vue?macro=true";
import { default as grow_45facility_45tourvineAyPeqeMeta } from "/tmp/build_f759bf60/pages/grow-facility-tour.vue?macro=true";
import { default as indexP5wRWZYdZ6Meta } from "/tmp/build_f759bf60/pages/index.vue?macro=true";
import { default as _91slug_93IQos2EekCvMeta } from "/tmp/build_f759bf60/pages/jobs/[slug].vue?macro=true";
import { default as indexQ1NvektvE1Meta } from "/tmp/build_f759bf60/pages/jobs/index.vue?macro=true";
import { default as cannabinoidsNnydhomZ6mMeta } from "/tmp/build_f759bf60/pages/learn/cannabinoids.vue?macro=true";
import { default as cannabis_45anatomydGoIBjHMrXMeta } from "/tmp/build_f759bf60/pages/learn/cannabis-anatomy.vue?macro=true";
import { default as cannabis_45history33i96ddxnIMeta } from "/tmp/build_f759bf60/pages/learn/cannabis-history.vue?macro=true";
import { default as michigan_45dispensary_45purchase_45limitssaGrHvrtuPMeta } from "/tmp/build_f759bf60/pages/learn/michigan-dispensary-purchase-limits.vue?macro=true";
import { default as terpenes_45over_45potencyKDJymeObMvMeta } from "/tmp/build_f759bf60/pages/learn/terpenes-over-potency.vue?macro=true";
import { default as what_45is_45cannabisVm0tb4S8cHMeta } from "/tmp/build_f759bf60/pages/learn/what-is-cannabis.vue?macro=true";
import { default as michigan_45dispensary_45near_45indianaVlsiVZDITAMeta } from "/tmp/build_f759bf60/pages/michigan-dispensary-near-indiana.vue?macro=true";
import { default as michigan_45dispensary_45near_45ohioulm1jCsp0iMeta } from "/tmp/build_f759bf60/pages/michigan-dispensary-near-ohio.vue?macro=true";
import { default as newsletterp6l7EKz1NLMeta } from "/tmp/build_f759bf60/pages/newsletter.vue?macro=true";
import { default as order_45trackerRPy08Yx9zMMeta } from "/tmp/build_f759bf60/pages/order-tracker.vue?macro=true";
import { default as privacy_45policyZlGMDU9fMrMeta } from "/tmp/build_f759bf60/pages/privacy-policy.vue?macro=true";
import { default as _91slug_93xHNvxTnwMCMeta } from "/tmp/build_f759bf60/pages/products/[slug].vue?macro=true";
import { default as quick_45orderTchdfD8kI4Meta } from "/tmp/build_f759bf60/pages/quick-order.vue?macro=true";
import { default as return_45policyXSQKd93boHMeta } from "/tmp/build_f759bf60/pages/return-policy.vue?macro=true";
import { default as reviewsxKS1InhkbpMeta } from "/tmp/build_f759bf60/pages/reviews.vue?macro=true";
import { default as staging_45loginjiGo8rYuppMeta } from "/tmp/build_f759bf60/pages/staging-login.vue?macro=true";
import { default as strain_45libraryB8x6TcH2CKMeta } from "/tmp/build_f759bf60/pages/strain-library.vue?macro=true";
import { default as terms_45of_45useCVblxOBclvMeta } from "/tmp/build_f759bf60/pages/terms-of-use.vue?macro=true";
import { default as thank_45you7cwQNdwjP2Meta } from "/tmp/build_f759bf60/pages/thank-you.vue?macro=true";
import { default as weedys_45wearopprhHZfGxMeta } from "/tmp/build_f759bf60/pages/weedys-wear.vue?macro=true";
import { default as wholesaleR1wuht5r3aMeta } from "/tmp/build_f759bf60/pages/wholesale.vue?macro=true";
export default [
  {
    name: "$100-ounces",
    path: "/$100-ounces",
    component: () => import("/tmp/build_f759bf60/pages/$100-ounces.vue").then(m => m.default || m)
  },
  {
    name: "accessibility",
    path: "/accessibility",
    component: () => import("/tmp/build_f759bf60/pages/accessibility.vue").then(m => m.default || m)
  },
  {
    name: "brands-slug",
    path: "/brands/:slug()",
    component: () => import("/tmp/build_f759bf60/pages/brands/[slug].vue").then(m => m.default || m)
  },
  {
    name: "build-a-pack",
    path: "/build-a-pack",
    component: () => import("/tmp/build_f759bf60/pages/build-a-pack.vue").then(m => m.default || m)
  },
  {
    name: "cannabis-pricing",
    path: "/cannabis-pricing",
    component: () => import("/tmp/build_f759bf60/pages/cannabis-pricing.vue").then(m => m.default || m)
  },
  {
    name: "checkout",
    path: "/checkout",
    component: () => import("/tmp/build_f759bf60/pages/checkout.vue").then(m => m.default || m)
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/tmp/build_f759bf60/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: dashboardHFRVqcQi8lMeta?.name,
    path: "/dashboard",
    meta: dashboardHFRVqcQi8lMeta || {},
    component: () => import("/tmp/build_f759bf60/pages/dashboard.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-confirm",
    path: "confirm",
    meta: confirmSjWPzuExAdMeta || {},
    component: () => import("/tmp/build_f759bf60/pages/dashboard/confirm.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "",
    component: () => import("/tmp/build_f759bf60/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-jobs-id",
    path: "jobs/:id()",
    component: () => import("/tmp/build_f759bf60/pages/dashboard/jobs/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-jobs-create",
    path: "jobs/create",
    component: () => import("/tmp/build_f759bf60/pages/dashboard/jobs/create.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-jobs",
    path: "jobs",
    component: () => import("/tmp/build_f759bf60/pages/dashboard/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-login",
    path: "login",
    meta: loginCJ7treLhRZMeta || {},
    component: () => import("/tmp/build_f759bf60/pages/dashboard/login.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-media-bundle-image-builder",
    path: "media/bundle-image-builder",
    meta: bundle_45image_45buildernpD5zGOf5yMeta || {},
    component: () => import("/tmp/build_f759bf60/pages/dashboard/media/bundle-image-builder.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-media",
    path: "media",
    meta: indexMdv259CX6QMeta || {},
    component: () => import("/tmp/build_f759bf60/pages/dashboard/media/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-menu",
    path: "menu",
    component: () => import("/tmp/build_f759bf60/pages/dashboard/menu/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-pages-slug",
    path: "pages/:slug(.*)*",
    meta: _91_46_46_46slug_933IwQLvziQ1Meta || {},
    component: () => import("/tmp/build_f759bf60/pages/dashboard/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-pages-home",
    path: "pages/home",
    meta: homec3E8f2SsZZMeta || {},
    component: () => import("/tmp/build_f759bf60/pages/dashboard/pages/home.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-pages",
    path: "pages",
    meta: indexR2xwN2wK4CMeta || {},
    component: () => import("/tmp/build_f759bf60/pages/dashboard/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-products-id",
    path: "products/:id()",
    meta: _91id_93gARRZfiZM9Meta || {},
    component: () => import("/tmp/build_f759bf60/pages/dashboard/products/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-products",
    path: "products",
    meta: indexz0fcocuKNNMeta || {},
    component: () => import("/tmp/build_f759bf60/pages/dashboard/products/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-reporting-forecasts",
    path: "reporting/forecasts",
    component: () => import("/tmp/build_f759bf60/pages/dashboard/reporting/forecasts.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-reviews-id",
    path: "reviews/:id()",
    meta: _91id_93yeRpbk80khMeta || {},
    component: () => import("/tmp/build_f759bf60/pages/dashboard/reviews/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-reviews",
    path: "reviews",
    meta: index0LYRzKI03PMeta || {},
    component: () => import("/tmp/build_f759bf60/pages/dashboard/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-settings",
    path: "settings",
    meta: settings4RCd3ucMSgMeta || {},
    component: () => import("/tmp/build_f759bf60/pages/dashboard/settings.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-theme",
    path: "theme",
    meta: themedBTwIWZwJLMeta || {},
    component: () => import("/tmp/build_f759bf60/pages/dashboard/theme.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-users-id",
    path: "users/:id()",
    meta: _91id_93l0qccFZyhvMeta || {},
    component: () => import("/tmp/build_f759bf60/pages/dashboard/users/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-users",
    path: "users",
    meta: index3MiApy7Hg3Meta || {},
    component: () => import("/tmp/build_f759bf60/pages/dashboard/users/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-web-vitals",
    path: "web-vitals",
    component: () => import("/tmp/build_f759bf60/pages/dashboard/web-vitals/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dispensary-birthday-deals-michigan",
    path: "/dispensary-birthday-deals-michigan",
    component: () => import("/tmp/build_f759bf60/pages/dispensary-birthday-deals-michigan.vue").then(m => m.default || m)
  },
  {
    name: "dispensary-deals",
    path: "/dispensary-deals",
    component: () => import("/tmp/build_f759bf60/pages/dispensary-deals.vue").then(m => m.default || m)
  },
  {
    name: "first-time-dispensary-deals",
    path: "/first-time-dispensary-deals",
    component: () => import("/tmp/build_f759bf60/pages/first-time-dispensary-deals.vue").then(m => m.default || m)
  },
  {
    name: "glass-jars",
    path: "/glass-jars",
    component: () => import("/tmp/build_f759bf60/pages/glass-jars.vue").then(m => m.default || m)
  },
  {
    name: "grow-facility-tour",
    path: "/grow-facility-tour",
    component: () => import("/tmp/build_f759bf60/pages/grow-facility-tour.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/tmp/build_f759bf60/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "jobs-slug",
    path: "/jobs/:slug()",
    component: () => import("/tmp/build_f759bf60/pages/jobs/[slug].vue").then(m => m.default || m)
  },
  {
    name: "jobs",
    path: "/jobs",
    component: () => import("/tmp/build_f759bf60/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: "learn-cannabinoids",
    path: "/learn/cannabinoids",
    component: () => import("/tmp/build_f759bf60/pages/learn/cannabinoids.vue").then(m => m.default || m)
  },
  {
    name: "learn-cannabis-anatomy",
    path: "/learn/cannabis-anatomy",
    component: () => import("/tmp/build_f759bf60/pages/learn/cannabis-anatomy.vue").then(m => m.default || m)
  },
  {
    name: "learn-cannabis-history",
    path: "/learn/cannabis-history",
    component: () => import("/tmp/build_f759bf60/pages/learn/cannabis-history.vue").then(m => m.default || m)
  },
  {
    name: "learn-michigan-dispensary-purchase-limits",
    path: "/learn/michigan-dispensary-purchase-limits",
    component: () => import("/tmp/build_f759bf60/pages/learn/michigan-dispensary-purchase-limits.vue").then(m => m.default || m)
  },
  {
    name: "learn-terpenes-over-potency",
    path: "/learn/terpenes-over-potency",
    component: () => import("/tmp/build_f759bf60/pages/learn/terpenes-over-potency.vue").then(m => m.default || m)
  },
  {
    name: "learn-what-is-cannabis",
    path: "/learn/what-is-cannabis",
    component: () => import("/tmp/build_f759bf60/pages/learn/what-is-cannabis.vue").then(m => m.default || m)
  },
  {
    name: "michigan-dispensary-near-indiana",
    path: "/michigan-dispensary-near-indiana",
    component: () => import("/tmp/build_f759bf60/pages/michigan-dispensary-near-indiana.vue").then(m => m.default || m)
  },
  {
    name: "michigan-dispensary-near-ohio",
    path: "/michigan-dispensary-near-ohio",
    component: () => import("/tmp/build_f759bf60/pages/michigan-dispensary-near-ohio.vue").then(m => m.default || m)
  },
  {
    name: "newsletter",
    path: "/newsletter",
    component: () => import("/tmp/build_f759bf60/pages/newsletter.vue").then(m => m.default || m)
  },
  {
    name: "order-tracker",
    path: "/order-tracker",
    component: () => import("/tmp/build_f759bf60/pages/order-tracker.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/tmp/build_f759bf60/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "products-slug",
    path: "/products/:slug()",
    component: () => import("/tmp/build_f759bf60/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: "quick-order",
    path: "/quick-order",
    component: () => import("/tmp/build_f759bf60/pages/quick-order.vue").then(m => m.default || m)
  },
  {
    name: "return-policy",
    path: "/return-policy",
    component: () => import("/tmp/build_f759bf60/pages/return-policy.vue").then(m => m.default || m)
  },
  {
    name: "reviews",
    path: "/reviews",
    component: () => import("/tmp/build_f759bf60/pages/reviews.vue").then(m => m.default || m)
  },
  {
    name: "staging-login",
    path: "/staging-login",
    meta: staging_45loginjiGo8rYuppMeta || {},
    component: () => import("/tmp/build_f759bf60/pages/staging-login.vue").then(m => m.default || m)
  },
  {
    name: "strain-library",
    path: "/strain-library",
    component: () => import("/tmp/build_f759bf60/pages/strain-library.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    component: () => import("/tmp/build_f759bf60/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: "thank-you",
    path: "/thank-you",
    component: () => import("/tmp/build_f759bf60/pages/thank-you.vue").then(m => m.default || m)
  },
  {
    name: "weedys-wear",
    path: "/weedys-wear",
    component: () => import("/tmp/build_f759bf60/pages/weedys-wear.vue").then(m => m.default || m)
  },
  {
    name: "wholesale",
    path: "/wholesale",
    component: () => import("/tmp/build_f759bf60/pages/wholesale.vue").then(m => m.default || m)
  }
]